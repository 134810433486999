.thesis-img{
    display: block;
    margin: 5vh auto;
    height: auto;
}

.cpation{
  text-align: center;
  color: rgba(132, 129, 129, 0.72);
  font-size: 12px;
}
.UBCImg1{
  width: 220px;
}

.UBCImg2{
 width: 450px;
}

.UBCImg3{
  width: 650px;
 }

.KICImg1{
  width: 320px;
}

.KICImg2{
  width: 750px;
}

.KICImg45{
  display: block;
  margin:  auto;
  width: fit-content;
}
.KICImg4{
  display: inline-block;
  margin-right:  20px ;
  width: 320px;
}
.KICImg5{
  display: inline-block;
  width: 320px;
  margin-left:  20px ;
}

.fixed-button {
    position: fixed;
    top: 50%;
    left: 5%; /* Adjust this value to position the button on the left side */
    transform: translateY(-50%);
  }
  
  .fixed-button button {
    padding: 10px;
    background-color: #bbb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .fixed-button button img {
     width: 20px;
  }
  
  .fixed-button button:hover {
    background-color:#23395d;
    
  }
  
  .fixed-button button svg {
    width: 20px; /* Adjust the size of the icon */
  }
  
  


@media (max-width: 768px) {
  .UBCImg2{
    width: 100%;
   }
   .UBCImg3{
    width: 100%;
   }

   .KICImg1{
    width: 100%;
  }
  .KICImg2{
    width: 100%;
  }
  .KICImg4{
    width: 100%;
    margin-right:  0 ;
    margin-bottom: 20px;
  }
  .KICImg5{
    width: 100%;
    margin-left:  0 ;
    margin-top: 20px;
  }
  .fixed-button {

    top: 85%;

  }

  }