
.header-container {
  text-align: center;

}


/* Header background with improved dot pattern and fade effect */
.header {
  position: absolute; /* Position header to not take up space */
  top: 0;
  left: 0;
  width: 100%;
  height: 250px; /* The height of the background */
  z-index: -1; /* Ensures the background stays behind other elements */
}

/* Adding the dots pattern and fade effect to the background */
.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle, #23395d 10%, transparent 10%), 
    radial-gradient(circle, #f5f5f5 10%, transparent 10%); /* Dots pattern */
  background-size: 20px 18px; /* Controls the space between dots */

  background-repeat: repeat; /* Ensure the pattern repeats */
  background-position: center; /* Center the pattern */
  z-index: -1; /* Ensure it stays behind the content */
}

/* Fade effect using pseudo-element */
.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255) 100%); /* Fade from the center */
  z-index: -1; /* Ensures the fade effect stays behind other content */
  pointer-events: none; /* Ensures it's not clickable */
}







/* .profile-image {
  margin-top: 5vh;
  width: 200px; 
  height: 200px; 
  border-radius: 3%;
  margin-bottom: 1vh;
} */

.profile-image {
  width: 180px; 
  height: 180px; 
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 7vh;
  margin-bottom: 20px;
  display: block;
  margin-left: 5px;
  /* margin-right: auto; */
}

.bio {
  width: 60%;
  margin: auto;
  text-align: left;
  line-height: 1.8;
}

.title {
  font-size: 1.7rem; 
  margin-bottom: 2vh;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  font-weight: bold;
}

.intro {
  text-align: left;
  background: linear-gradient(135deg, rgba(35, 57, 93, 0.1) 0%, rgba(245, 245, 245, 0.1) 100%); /* Barely visible gradient between blue and light gray */
  padding: 40px 20px;
  border-radius: 12px;
  margin: 20px auto 1px auto; /* Centers the section with space around it */
  position: relative;
  z-index: 1; /* Makes sure it's above other content */
}


/* Adding a subtle border on top for emphasis */
.intro::before {
  content: "";
  display: none;
  width: 50px;
  height: 5px;
  background: #23395d; /* Accent color from your primary palette */
  margin: 0 auto 20px auto;
}


@media (max-width: 768px) {
  .profile-image {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .title {

    text-align: center;
  }

  .intro{
    text-align: center;
  }

  .intro::before{
    display: block;
  }


}


.sub-title{
  font-size: 1.6rem; /* Adjust according to your preference */
  margin-bottom: 10px;
  text-align: left;
}

.section-list {
  list-style-type: none;
  padding-left: 0; /* Reset padding */
}

.section-list-item {
  margin-bottom: 10px; /* Add some space between items */
}

.spacing{
  height: 1vh;
}
mark {
  background-color: transparent;
  color: #23395d;
  font-weight: 500;
}

#co-link{
  color: black;
}



#co-link:hover{
  color: #bbb;
}

/* Theses display on home page */

.featured-items {
  display: grid;
  grid-template-columns: 50% 2fr;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.project-item {
  position: relative; /* To position the icon */
  background: linear-gradient(135deg, rgba(35, 57, 93, 0.1) 0%, rgba(245, 245, 245, 0.1) 100%); /* Barely visible gradient between blue and light gray */

  padding: 20px;
  border-radius: 8px;
}

.project-info {
  pointer-events: none; /* Prevents text from being clickable */
}

.project-name {
  font-size: 18px;
  margin-bottom: 8px;
}

.project-year,
.project-type {
  font-size: 14px;
  margin-bottom: 4px;
}

.project-type {
  font-weight: bold;
}


/* Hover effect */
.project-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Remove link styles */
.project-link {
  text-decoration: none;
  color: inherit;
}

.project-link:hover {
  text-decoration: none;
  color: inherit;
}


/* footer */

.footer {
  background-color: #333;
  color: #fff;
  padding: 10px 0px ;
  text-align: center;
  font-size: 0.9rem;
  position: absolute;
  left: 0;
  bottom: -5%;
  width: 100%;
 
}

.footer-item {
  display: inline-block;
  margin: 0px 10px;

}
.footer-item a{
  color: white;
}

.footer-item a:hover{
  color: #bbb;
} 

.footer-item a img:hover{
  background-color: #bbb;
} 

.footer-item img{
  position: relative;
  top: 4px;
  width: 15px;
  height: 15px;
  padding: 3px;
  background-color: white;
  margin: 0 5px;
}

/* For tablets and below */
@media (max-width: 768px) {
  .bio {
    font-size: 1.0rem; /* Adjust according to your preference */
    width: 80%;

  }

  .title {
    font-size: 1.7rem;

  }

  .featured-items{
    grid-template-columns: auto;
  } 
}
